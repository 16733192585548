import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


/** MOBILE NAVIGATION ANIMATIONS **/
const mobileMenuOpener = document.querySelector('.open-mobile-menu');
const closeMenu = document.querySelector('.close');
const navEl = document.querySelector('header nav');

let mobileMenuTween = gsap.fromTo('header nav li', {
    opacity: 0,
    translateY: '-30px',
}, {
    opacity:1,
    translateY: 0,
    stagger: 0.2,
    paused: true
});


mobileMenuOpener.addEventListener('click',function() {
    navEl.classList.add('active');
    document.querySelector('html').classList.add('scroll-lock');
    mobileMenuTween.restart();
});

closeMenu.addEventListener('click',function() {
    navEl.classList.remove('active');
    document.querySelector('html').classList.remove('scroll-lock');
})



