<template>
    <!-- MAIN SLIDER -->
    <swiper :modules="modules" :navigation="true" :thumbs="{ swiper: thumbsSwiper }" class="main-slider">
        <swiper-slide v-for="image in images">
            <img :src="image" alt="Image">
        </swiper-slide>
    </swiper>


    <!-- THUMBNAIL SLIDER -->
    <swiper @swiper="setThumbsSwiper" :modules="modules" :slides-per-view="4" watch-slides-progress class="thumb-slider" :space-between="16" :breakpoints="swiperOptions.breakpoints">
        <swiper-slide v-for="image in images">
            <img :src="image" alt="Image">
        </swiper-slide>
    </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Thumbs,Navigation } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/thumbs";
import "swiper/css/navigation";

export default {
    name: "ProductDetailSlider",
    props: {
        images: {
            type: Array,
            required: true
        },
    },
    components: {
        Swiper,SwiperSlide
    },

    data() {
        return {
            images: this.images,
            swiperOptions: {
                breakpoints: {
                    0: {
                        slidesPerView: 2
                    },

                    580: {
                        slidesPerView: 4
                    }
                }
            }
        }
    },

    setup() {
        const thumbsSwiper = ref(null);

        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };
        return {
            modules: [Thumbs,Navigation],
            thumbsSwiper,
            setThumbsSwiper,
        };
    },

}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstracts/_variables.scss';
@import "bootstrap/scss/bootstrap-utilities";

.main-slider {
    border: 1px solid #F4F4F4;
    .swiper-slide {
        aspect-ratio: 1.5/1;
        @include media-breakpoint-down(sm) {
            aspect-ratio: 1/1;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.thumb-slider {
    margin-top: 20px;
    .swiper-slide {
        border: 1px solid #F4F4F4;
        cursor: pointer;
        height: 103px;
        opacity: .5;
        &-thumb-active {
            border: 2px solid $primary-color;
            opacity: 1;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

</style>
