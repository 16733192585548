<template>
    <div class="position-relative">
        <h2>Our team</h2>

        <swiper @swiper="onSwiper"
                :allow-touch-move="false"
                :space-between="52"
                :breakpoints="swiperOptions.breakpoints">


            <swiper-slide v-for="(item,index) in items" :key="index">
                <div class="thumbnail">
                    <a :href="item.link">
                        <img :src="item.image" alt="Team image">
                        <span class="hover-content">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9993 18.8225L14.781 13.6041C16.1365 11.9463 16.803 9.83087 16.6425 7.69543C16.4821 5.55999 15.507 3.5679 13.919 2.13123C12.331 0.694554 10.2515 -0.0767949 8.1107 -0.0232684C5.96991 0.0302581 3.93158 0.904564 2.41734 2.41881C0.903099 3.93305 0.0287933 5.97137 -0.0247333 8.11216C-0.0782598 10.253 0.693089 12.3324 2.12976 13.9204C3.56644 15.5085 5.55852 16.4836 7.69396 16.644C9.82941 16.8044 11.9448 16.138 13.6027 14.7825L18.821 20.0008L19.9993 18.8225ZM8.33266 15.0008C7.01412 15.0008 5.72519 14.6098 4.62886 13.8773C3.53253 13.1447 2.67805 12.1035 2.17347 10.8854C1.66888 9.66718 1.53686 8.32673 1.79409 7.03353C2.05133 5.74032 2.68627 4.55243 3.61862 3.62008C4.55097 2.68773 5.73885 2.05279 7.03206 1.79556C8.32527 1.53832 9.66571 1.67035 10.8839 2.17493C12.1021 2.67952 13.1433 3.534 13.8758 4.63033C14.6083 5.72665 14.9993 7.01559 14.9993 8.33413C14.9973 10.1016 14.2943 11.7962 13.0445 13.046C11.7947 14.2958 10.1002 14.9988 8.33266 15.0008Z" fill="#fff"/>
                            </svg>
                            <p>show more</p>
                        </span>
                    </a>
                </div>

                <h6>{{ item.title }}</h6>
                <p><a :href="'tel:'+item.phone_href">{{ item.phone }}</a></p>
                <p><a :href="'mailto:'+item.email_href">{{ item.email }}</a></p>

                <a :href="item.link" class="show-more">show more</a>
            </swiper-slide>
        </swiper>

            <button type="button" @click="slidePrev()" class="nav-btn prev"><img src="/img/our-team-arrow.svg" alt="Left arrow"></button>
            <button type="button" @click="slideNext()" class="nav-btn next"><img src="/img/our-team-arrow.svg" alt="Right arrow"></button>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

export default {
    name: "OurTeamSlider",
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        items: {
            type: Array,
            required: true
        },
    },

    data() {
        return {
            swiper: null,
            swiperOptions: {
                breakpoints: {
                    0: {
                        slidesPerView: 1
                    },

                    580: {
                        slidesPerView: 2
                    },

                    992: {
                        slidesPerView: 3
                    },
                    1100: {
                        slidesPerView: 4
                    }
                }
            }
        }
    },

    methods: {
        onSwiper(swiper) {
            this.swiper = swiper
        },
        slidePrev() {
            this.swiper.slidePrev();
        },
        slideNext() {
            this.swiper.slideNext();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../sass/abstracts/mixins";
@import "../../sass/abstracts/variables";
@import "bootstrap/scss/bootstrap-utilities";
.swiper {
    padding-right: 10%;
    padding-left: 14vw;
    @media (max-width: 768px) {
        padding: 0 15px;
    }
}

.nav-btn {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    z-index: 3;

    &.prev {
        left: 30px;
        transform: scaleX(-1);
    }
    &.next {
        right: 30px;
    }
}

h2 {
    @include fluid-font($min-vw,$max-vw, 35px, 50px);
    padding-left: 14vw;
    @media (max-width: 768px) {
        padding-left: 15px;
    }
}

.thumbnail {
    margin-bottom: 15px;
    aspect-ratio: 1/1.1;
    position: relative;
    z-index: 999;
    .hover-content {
        z-index: 3;
        text-align: center;
        transition: opacity .3s ease-in-out;
        opacity: 0;
        svg {
            transition: transform .3s ease-in-out;
            transform: scale(0);
        }
        p {
            text-transform: uppercase;
            color: $white;
            letter-spacing: 2px;
            font-size: rem(13px);
            margin-top: 7px;
            font-weight: 600;
            transition: transform .3s ease-in-out;
            transform: translateY(15px);
        }
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        &:before {
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($black,.4);
            transition: all .3s ease-in-out;
            z-index: 1;
            opacity: 0;
        }
        &:hover {
            .hover-content {
                opacity: 1;
                p {
                    transform: translateY(0);
                }

                svg {
                    transform: scale(1);
                }
            }
            &:before {
                opacity: 1;
            }
            img {
                transform: scale(1.15);
            }
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform .3s ease-in-out;
        will-change: transform;
    }
}

h6 {
    font-size: rem(20px);
    margin-bottom: 15px;
}

p {
    margin-bottom: 0;
    a {
        color: $primary-color;
        font-weight: 600;
        position: relative;
        &:hover:after {
            width: 100%;
        }
        &:after {
            content: " ";
            width: 0;
            height: 1px;
            background-color: $primary-color;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: width .3s ease-in-out;
        }
    }
}

.show-more {
    padding-bottom: 3px;
    text-transform: uppercase;
    color: $primary-color;
    letter-spacing: 2px;
    position: relative;
    font-weight: 500;
    font-size: rem(12px);
    margin: 20px 0 4px 0;
    display: inline-block;
    padding-right: 40px;
    &:before {
        content: " ";
        width: calc(100% - 40px);
        height: 1px;
        background-color: rgba($primary-color,.14);
        left: 0;
        bottom: 0;
        position: absolute;
    }
    &:after {
        content: " ";
        width: 28px;
        height: 29px;
        background: url('/img/right-arrow-grey.svg');
        transition: all .3s ease-in-out;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover:after {
        right: -10px;
    }
}
</style>
