<template>
    <Slider
        @change="changeValues"
        v-model="values"
        :min="minPrice"
        :max="maxPrice"
        :tooltips="false"
        :lazy="false"
        class="slider-brown"
    />
    <div class="inputs d-flex justify-content-between mt-3 gap-3">
        <div class="d-flex align-items-center">
            <strong>$</strong>
            <input
                @input="handleInputFrom($event)"
                @change="changeValues(values)"
                :value="priceFrom"
            />
        </div>

        <div class="d-flex align-items-center">
            <strong>$</strong>
            <input
                @input="handleInputTo($event)"
                @change="changeValues(values)"
                :value="priceTo"
            />
        </div>
    </div>
</template>

<script>
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";

export default {
    name: "PriceRangeSlider",
    props: {
        minPrice: {
            type: Number,
            required: true,
        },

        maxPrice: {
            type: Number,
            required: true,
        },
        minPriceActual: {
            type: Number,
            required: true,
        },

        maxPriceActual: {
            type: Number,
            required: true,
        },
    },
    components: {
        Slider,
    },
    data() {
        return {
            values: [this.minPriceActual, this.maxPriceActual],
        };
    },

    computed: {
        priceFrom() {
            return this.formattedPrice(this.values[0]);
        },

        priceTo() {
            return this.formattedPrice(this.values[1]);
        },
    },

    methods: {
        changeValues(values) {
            Livewire.emit("changePrices", values);
        },

        handleInputFrom(event) {
            this.values[0] = event.value;
        },

        handleInputTo(event) {
            this.values[1] = event.value;
        },

        formattedPrice(value) {
            let val = (value / 1).toFixed(0).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "resources/sass/abstracts/_variables.scss";
.slider-brown {
    --slider-connect-bg: #857654;
    --slider-handle-ring-color: #857654;
}
.inputs {
    input {
        width: 70px;
        outline: none;
        border: 1px solid $grey-bg;
        margin-right: 5px;
        text-align: center;
    }
}
</style>
