<template>
    <section
        class="testimonial"
        :class="variant"
    >
        <div class="container container-secondary">
            <swiper
                :modules="modules"
                :navigation="true"
                :pagination="true"
                :autoplay="{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }"
                :speed="3000"
                loop
            >
                <swiper-slide
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <div class="testimonial-body">
                        {{ item.text }}

                        <div class="testimonial-name">
                            {{ item.name }}
                            <span class="d-block">
                                {{ item.position }}
                            </span>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>

        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        variant: {
            type: String,
        },
    },
    setup() {
        return {
            modules: [
                Navigation,
                Pagination,
                Autoplay
            ],
        };
    },
};

</script>
